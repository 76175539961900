import { useLocation } from "react-router";
import { staticFiles } from "..";
import { useEffect, useState, useMemo } from "react";
import { cityIdState } from "../../App";

export const Cover = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const [cityId] = cityIdState.useState();

  useEffect(() => {
    setCurrentPath(location?.pathname);
  }, [location]);

  const getBannerImages = useMemo(() => {
    if (currentPath === "/city-attractions/museum-gallery") {
      return {
        backgroundImage: staticFiles.images.moma_banner,
        mobileBanner: staticFiles.images.moma_banner_mobile,
        pcBanner: staticFiles.images.moma_banner_pc,
      };
    } else if (currentPath === "/package-tour/ba-pass" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.sf_bigapple_pass,
        mobileBanner: staticFiles.images.sf_bigapple_pass_banner_mobile,
        pcBanner: staticFiles.images.sf_bigapple_pass_banner_pc,
      };
    } else if (currentPath === "/package-tour/city-pass" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.sf_city_pass,
        mobileBanner: staticFiles.images.sf_city_pass_banner_mobile,
        pcBanner: staticFiles.images.sf_city_pass_banner_pc,
      };
    } else if (currentPath === "/package-tour/explore-pass" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.sf_explore_pass,
        mobileBanner: staticFiles.images.sf_explore_banner_mobile,
        pcBanner: staticFiles.images.sf_explore_banner_pc,
      };
    } else if (currentPath === "/city-attractions/activities" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.SF_Activity_banner,
        mobileBanner: staticFiles.images.SF_Activity_mobile,
        pcBanner: staticFiles.images.SF_Activity_pc,
      };
    } else if (currentPath === "/city-attractions/observations" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.Bigbus_banner,
        mobileBanner: staticFiles.images.Bigbus_mobile,
        pcBanner: staticFiles.images.Bigbus_pc,
      };
    } else if (currentPath === "/package-tour/city-pass") {
      return {
        backgroundImage: staticFiles.images.ny_city_pass,
        mobileBanner: staticFiles.images.ny_city_pass_banner_mobile,
        pcBanner: staticFiles.images.ny_city_pass_banner_pc,
      };
    } else if (currentPath === "/city-attractions/observations") {
      return {
        backgroundImage: staticFiles.images.observations_banner,
        mobileBanner: staticFiles.images.observations_mobile,
        pcBanner: staticFiles.images.observations_pc,
      };
    } else if (currentPath === "/guide-tour/doson-tour") {
      return {
        backgroundImage: staticFiles.images.doson_banner,
        mobileBanner: staticFiles.images.doson_mobile,
        pcBanner: staticFiles.images.doson_pc,
      };
    } else if (currentPath === "/guide-tour/neighbour-tour") {
      return {
        backgroundImage: staticFiles.images.neighbour_banner,
        mobileBanner: staticFiles.images.neighbour_mobile,
        pcBanner: staticFiles.images.neighbour_pc,
      };
    } else if (currentPath === "/city-attractions/rides-cruises") {
      return {
        backgroundImage: staticFiles.images.cruise_cover_bg,
        mobileBanner: staticFiles.images.cruise_mobile_bannerFont,
        pcBanner: staticFiles.images.cruise_pc_bannerFont,
      };
    } else if (currentPath === "/city-attractions/activities") {
      return {
        backgroundImage: staticFiles.images.activity_cover_bg,
        mobileBanner: staticFiles.images.activity_pc_bannerFont,
        pcBanner: staticFiles.images.activity_mobile_bannerFont,
      };
    } else if (currentPath === "/city-attractions/bus") {
      return {
        backgroundImage: staticFiles.images.bus_cover_bg,
        mobileBanner: staticFiles.images.bus_pc_bannerFont,
        pcBanner: staticFiles.images.bus_mobile_bannerFont,
      };
    } else if (currentPath === "/city-attractions/airport") {
      return {
        backgroundImage: staticFiles.images.airport_cover_bg,
        mobileBanner: staticFiles.images.airport_mobile_bannerFont,
        pcBanner: staticFiles.images.airport_pc_bannerFont,
      };
    } else if (currentPath === "/package-tour/ba-pass") {
      return {
        backgroundImage: staticFiles.images.big_apple_pass_cover_bg,
        mobileBanner: staticFiles.images.big_apple_pass_mobile_bannerFont,
        pcBanner: staticFiles.images.big_apple_pass_pc_bannerFont,
      };
    } else if (currentPath === "/package-tour/city-pass") {
      return {
        backgroundImage: staticFiles.images.big_apple_pass_cover_bg,
        mobileBanner: staticFiles.images.big_apple_pass_mobile_bannerFont,
        pcBanner: staticFiles.images.big_apple_pass_pc_bannerFont,
      };
    } else if (currentPath === "/guide-tour/manhattan-night") {
      return {
        backgroundImage: staticFiles.images.NY_nighttour_banner,
        mobileBanner: staticFiles.images.NY_nighttour_mobile,
        pcBanner: staticFiles.images.NY_nighttour_pc,
      };
    } else if (currentPath === "/package-tour/explore-pass") {
      return {
        backgroundImage: staticFiles.images.NY_explore_pass_banner,
        mobileBanner: staticFiles.images.NY_explore_pass_mobile,
        pcBanner: staticFiles.images.NY_explore_pass_pc,
      };
    } else if (currentPath === "/cart") {
      return {
        backgroundImage: staticFiles.images.Cart_banner,
        mobileBanner: staticFiles.images.Cart_mobile,
        pcBanner: staticFiles.images.Cart_pc,
      };
    } else if (currentPath === "/musicals_view") {
      return {
        backgroundImage: staticFiles.images.musical_cover_bg,
        mobileBanner: staticFiles.images.musical_mobile_bannerFont,
        pcBanner: staticFiles.images.musical_pc_bannerFont,
      };
    } else if (currentPath === "/user/log-in") {
      return {
        backgroundImage: staticFiles.images.login_banner,
        mobileBanner: staticFiles.images.login_banner_mobile,
        pcBanner: staticFiles.images.login_banner_pc,
      };
    } else if (currentPath === "/about") {
      return {
        backgroundImage: staticFiles.images.tamice_page_cover_bg,
        mobileBanner: staticFiles.images.tamice_page_mobile_bannerFont,
        pcBanner: staticFiles.images.tamice_page_pc_bannerFont,
      };
    } else if (currentPath === "/sim-card") {
      return {
        backgroundImage: staticFiles.images.sim_cover_bg,
        mobileBanner: staticFiles.images.sim_mobile_bannerFont,
        pcBanner: staticFiles.images.sim_pc_bannerFont,
      };
    } else if (currentPath === "/trip-info") {
      return {
        backgroundImage: staticFiles.images.ny_event_cover_bg,
        mobileBanner: staticFiles.images.ny_event_mobile_bannerFont,
        pcBanner: staticFiles.images.ny_event_pc_bannerFont,
      };
    } else if (
      currentPath === "/user/sign-up" ||
      currentPath === "/user/register" ||
      currentPath === "/user/forgot-password"
    ) {
      return {
        backgroundImage: staticFiles.images.signup_banner,
        mobileBanner: staticFiles.images.sign_up_mobile,
        pcBanner: staticFiles.images.sign_up_pc,
      };
    } else if (currentPath === "/city-attractions/rides-cruises") {
      return {
        backgroundImage: staticFiles.images.cruise_banner,
        mobileBanner: staticFiles.images.cruise_banner_mobile,
        pcBanner: staticFiles.images.cruise_banner_pc,
      };
    } else if (currentPath === "/user/non-member-order-lookup") {
      return {
        backgroundImage: staticFiles.images.my_page_cover_bg,
        mobileBanner: staticFiles.images.my_page_mobile_bannerFont,
        pcBanner: staticFiles.images.my_page_pc_bannerFont,
      };
    } else if (currentPath === "/my-page") {
      return {
        backgroundImage: staticFiles.images.my_page_cover_bg,
        mobileBanner: staticFiles.images.my_page_mobile_bannerFont,
        pcBanner: staticFiles.images.my_page_pc_bannerFont,
      };
    } else if (currentPath === "/guide-tour/manhattan-day") {
      return {
        backgroundImage: staticFiles.images.daytour_banner,
        mobileBanner: staticFiles.images.daytour_mobile,
        pcBanner: staticFiles.images.daytour_pc,
      };
    } else if (currentPath === "/guide-tour/un-tour") {
      return {
        backgroundImage: staticFiles.images.untour_banner,
        mobileBanner: staticFiles.images.untour_mobile,
        pcBanner: staticFiles.images.untour_pc,
      };
    } else if (currentPath === "/sf-trip-info" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.sf_event_cover_bg,
        mobileBanner: staticFiles.images.sf_event_mobile_bannerFont,
        pcBanner: staticFiles.images.sf_event_pc_bannerFont,
      };
    } else if (currentPath === "/guide-tour/sf-museum" && cityId === 36) {
      return {
        backgroundImage: staticFiles.images.sf_museum_cover_bg,
        mobileBanner: staticFiles.images.sf_museum_mobile_bannerFont,
        pcBanner: staticFiles.images.sf_museum_pc_bannerFont,
      };
    } else {
      // Default case
      return {
        backgroundImage: staticFiles.images.toprock_cover_bg,
        mobileBanner: staticFiles.images.toprock_mobile_bannerFont,
        pcBanner: staticFiles.images.toprock_pc_bannerFont,
      };
    }
  }, [currentPath]);

  return (
    <div className="w-full">
      <div className="relative flex justify-center w-full h-1/2">
        <img
          className="object-cover object-center w-full h-[400px]"
          src={getBannerImages.backgroundImage}
          alt="Background"
        />
        <img
          className="absolute lg:hidden top-[22vh] object-cover object-center h-[90px]"
          src={getBannerImages.mobileBanner}
          alt="Mobile Banner"
        />
        <img
          // className="absolute hidden lg:block top-[18vh] object-cover object-center"
          className="absolute hidden object-cover object-center transform -translate-x-1/2 -translate-y-1/2 lg:block top-1/2 left-1/2"
          src={getBannerImages.pcBanner}
          alt="PC Banner"
        />
      </div>
    </div>
  );
};
